// /* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }

// body { margin: 0; font-family: Ubuntu,"Verdana",sans-serif; font-weight: 400; }



// // body { margin: 0; font-family: Ubuntu,sans-serif }
// @import "assets/styles/scss/_material.theme.scss";
// @import "assets/styles/scss/core/_main-header.scss";
// @import "assets/styles/scss/core/_main-navbar.scss";
// @import "assets/styles/scss/mixins/_flex.scss";
// @import "assets/styles/scss/material/custom.scss";
// @import "assets/styles/scss/core/_session.scss";



// // adding required bootstrap
// @import "assets/styles/scss/bootstrap/variable";

// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";

// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/utilities/api";
// @import "~bootstrap/scss/_tables.scss";

// @import "assets/styles/scss/bootstrap/reset";
// //

/* You can add global styles to this file, and also import other style files */

// html,
// body {
//     height: 100%;
// }

// body {
//     margin: 0;
//     font-family: Ubuntu, "Verdana", sans-serif;
// }

// html, body { height: 100%; }
:focus, :hover{outline: none !important; text-decoration: none !important;}
::ng-deep body{
   /* font: normal normal medium 20px/40px Ubuntu; */
//    font-size: 14px;
font-size: 12px;
   /* line-height: 40px; */
   font-family: 'Ubuntu', Verdana, sans-serif;
   letter-spacing: 0px;
   color: #717176 !important;
   opacity: 1;
   margin: 0;
}

// body { margin: 0; font-family: Ubuntu,sans-serif }
@import "assets/styles/scss/_material.theme.scss";
@import "assets/styles/scss/core/_main-header.scss";
@import "assets/styles/scss/core/_main-navbar.scss";
//@import "assets/styles/scss/mixins/_flex.scss";
//@import "assets/styles/scss/core/_session.scss";
@import "assets/styles/scss/material/custom.scss";
// @import '~bootstrap/dist/css/bootstrap.min.css';
@import "assets/styles/scss/bootstrap/variable";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/_tables.scss";
@import "assets/styles/scss/bootstrap/reset";
//
[mat-raised-button][mat-button-sm] {
    font-size: 13px;
    line-height: 30px;
    padding: 0 8px;
    margin: 0 8px;
    // margin: 3 15px;
    box-shadow: 0px 4px 12px #00000029;
    border-radius: 2px;
    opacity: 1;
    // width: 140px;
    // height: 40px;
    color:  #FFFFFF;
    // font-size: 14px;
   //  font-size: 12px;
    font-family: 'Ubuntu', Verdana, sans-serif;
}

//bootstrap
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/utilities/api";
// @import "~bootstrap/scss/_tables.scss";
// @import "assets/styles/scss/bootstrap/reset";




