.main-nav{
    padding-top: 3%;
    display : flex;
    align-items: center;
    justify-content: center;
    
}
.panel-align {
    margin-top: 100px;
    position: relative;
}
.headerHold{
    padding-bottom: 15px;
    border-bottom: 1px solid #71717626!important;
    margin-bottom: 20px;
    width: 83%;
    position: fixed;
    z-index: 1;
    background-color: #fff;
    top: 70px;
    // top: 55px;
    padding-top: 20px;
    box-shadow: 0 4px 4px -4px grey;
    // position: fixed;
    // top: 70px;
    // padding-top: 20px;
    // padding-bottom: 25px;
    // box-shadow: 0px 4px 4px -4px grey;
    // z-index: 1;
    // background-color: white;
}
.font{
    font-family:   Ubuntu, Verdana, sans-serif;
}
.toggle-switch-container{
    position: fixed;
    top: 70px;
    padding-top: 20px;
    padding-bottom: 25px;
    box-shadow: 0px 4px 4px -4px grey;
    z-index: 1;
    background-color: white;
}
.mat-button-toggle {
    // color: mat-color($accent);
    background: #fff;
    // background-color: #ECF4F8;
    // border-bottom: solid 3px mat-color($primary, 400);
 
    border-bottom: solid 1px mat-color($primary, 400);
    border-top: solid 1px mat-color($primary, 400);
    border-left: solid 1px mat-color($primary, 400);
    border-right: solid 1px mat-color($primary, 400);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // border: 1px solid mat-color($primary, 400);
   
    font-family: Ubuntu,Verdana,sans-serif;
    letter-spacing: 0;
    // line-height: 30px;
    color: mat-color($primary);
    opacity: 1;
    flex-wrap: wrap;
   
    width:200px
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding-right: 3px;
    padding-left: 3px;
    line-height: 45px;
    // width:200px
}

.mat-button-toggle-appearance-standard {
    // line-height: 30px;
    width: 100%;
    vertical-align: middle;
    
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: #fafafc;
    // font-weight: bold;

    // font-size: 14px;
    font-size: 16px;
    font-family: Ubuntu,Verdana,sans-serif;
    letter-spacing: 0px;
    // color: #5A5A5F;
    opacity: 1;
}

.mat-button-toggle-checked {
    background-color: mat-color($primary);
}


///
.menu-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header{
    flex-shrink: 0;
    flex-grow: 0;
}

.content{
    flex-shrink: 1;
    flex-grow: 1;
    padding: 3px 3px;
    position: relative;
    overflow-y: auto;
}
  
.footer{
    flex-shrink: 0;
    flex-grow: 0;
    background: #eee;
    padding: 3px 15px;
}
@media (min-width: 1382px) and (min-height:1488px) {
    .mat-button-toggle-group {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        line-height: 32px;
        width: 1400px;
        font-size:16px;
        // font-size:14px;      
        flex-wrap: wrap;
      }
}
@media (min-width: 1530px) and (min-height:1488px) {
    .mat-button-toggle-group {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        line-height: 32px;
        width: 1400px;
        font-size:16px;
        // font-size:14px;       
        
      }
}
//menu
// .mat-button-toggle-group {
//     position: relative;
//     display: inline-flex;
//     vertical-align: middle;
//     line-height: 32px;
//     width: 1400px;
//     // font-size:12px;
//     font-size:16px;
    
    
//   }

  .mat-button-toggle-label-content {
    line-height: 30px !important;
    opacity: 1;
    letter-spacing: 0;
    justify-content: space-around;
    flex-wrap: inherit;
    align-items: center;
    display: flex!important;
    
  }


  .d-flex {
    display: flex;
    justify-content: space-around;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: solid 0px #e0e0e0;
}

.mat-card{
    margin-left:15px;
    box-shadow: 0 1px 6px #00000029;
}


