// @use '@angular/material' as mat;
@import '~@angular/material/theming';


$bpost-red: (
    50 : #fde5e7,
    100 : #fabec3,
    200 : #f7939b,
    300 : #f46773,
    400 : #f14755,
    500 : #ef2637,
    600 : #ed2231,
    700 : #eb1c2a,
    800 : #e81723,
    900 : #e40d16,
    A100 : #ffffff,
    A200 : #ffdcdd,
    A400 : #ffa9ab,
    A700 : #ff9093,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$bpost-grey: (
    50 : #ebebec,
    100 : #cececf,
    200 : #adadaf,
    300 : #8c8c8f,
    400 : #737377,
    500 : #5a5a5f,
    600 : #525257,
    700 : #48484d,
    800 : #3f3f43,
    900 : #2e2e32,
    A100 : #8d8df6,
    A200 : #5e5ef3,
    A400 : #1f1fff,
    A700 : #0505ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// $primary: mat-palette($mat-red,600);   //#EF2637;
// $accent:  mat-palette($mat-grey,700); //#5A5A5F;
$primary: mat-palette($bpost-red); 
$accent:  mat-palette($bpost-grey);
// $primary: mat-palette($bpost-grey); 
// $accent:  mat-palette($bpost-red);
// $warn: mat-palette($mat-indigo); //#FFFFFF;
$warn: mat-palette($mat-red); //#FFFFFF;
$theme: mat-light-theme($primary,$accent,$warn);
$background: map-get($theme,background);
$foreground: map-get($theme, foreground);

//Typography
// $custom-typography: mat-typography-config(
//     $font-family : "Ubantu,'Verdana',sans-serif"
// );

$custom-typography: mat-typography-config(

$font-family: "Ubuntu,'Verdana',sans-serif",

$headline: mat-typography-level(32px, 48px, 700),


$body-1: mat-typography-level(12px, 14px, 400)

);

// $body-1: mat-typography-level(14px, 16px, 400)

// $custom-typography: mat.define-typography-level(
//     $font-family : "Ubantu,'Verdana',sans-serif",
//     $font-weight: 200,
//     $font-size: 1rem,
//     $line-height: 1,
//     $letter-spacing: normal
// );






@include mat-core($custom-typography);
// @include mat.core($custom-typography);

@include angular-material-theme($theme);


