// .main-header{
//     background-color: white;
// }
.main-header {
    // background-color: white;
    // justify-content: space-between;
    // flex-wrap: inherit;
    // align-items: center;
    // display: flex!important;
    // box-shadow: 0 3px 6px #00000029;

    width: 100%;
    display: flex;
    min-height: 70px;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    background-color: #fff;
}

.headerMenu {
    justify-content: space-around;
    flex-wrap: inherit;
    align-items: center;
    display: flex!important;
    margin-right: 5%;
}

.logoff {
    cursor: pointer;
    // font-size: 14px;
    font-size: 12px;
    font-family: Ubuntu, Verdana, sans-serif!important;
    color: #717176!important;
    margin-left: 7%;
}


///
/// 
.header-column {
width: 100%;
display: flex;
min-height: 70px;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
background-color: #fff;
}


.header-icons {
display: inline-flex;
margin-left: auto;
align-items: center;
}

.bpost-logo {
width: 72px !important;
height: 40px !important;
cursor: pointer;
opacity: 1;
outline-color: #c3dcfb;
}
.faq{
outline-color: #c3dcfb;
}

.header-text {
/* font: normal normal normal 14px/16px Ubuntu; */
// font-size: 14px;
font-size: 12px;
font-family: Ubuntu, Verdana, sans-serif;
letter-spacing: 0px;
color: #717176;
margin-right: 20px;
opacity: 1;
cursor: pointer;
}
.remove-focus{
outline: none !important;
}

.header-dropdown {
/* font: normal normal normal 14px/16px Ubuntu; */
// font-size: 14px;
font-size: 12px;
font-family: Ubuntu, Verdana, sans-serif;
letter-spacing: 0px;
color: #717176;
outline: 0px;
opacity: 1;
border-width: 0;
cursor: pointer;
text-transform: uppercase;
}
.form-select.header-dropdown:focus, .form-select.header-dropdown:hover{
outline: none !important;
} 

/* overwriting bootstrap css */

.dropdown-toggle::after {
display: none;
}


.header-dropdown ul li {
width: 100%;
text-align: center;
background: #fff;
padding-top: 10px;
}

.dropdown a {
/* font: normal normal normal 14px/16px Ubuntu; */
// font-size: 14px;
font-size: 12px;
font-family: Ubuntu, Verdana, sans-serif;
letter-spacing: 0px;
color: #717176;
opacity: 1;
cursor: pointer;
}

.dropdown-menu {
min-width: 7rem;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.help {
    cursor: pointer;
    font-size: 16px;
    font-family: Ubuntu, Verdana, sans-serif!important;
    color: #717176!important;
    margin-left: 2%;
   
}

.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    top:0px;
}

@media(max-width:420px){
.header-column .me-5{
    margin-right: 1em !important;
}
}

.toggle-switch-container {
    position: fixed;
    top: 178px;
    padding-top: 20px;
    padding-bottom: 25px;
    box-shadow: 0 4px 4px -4px grey;
    z-index: 1;
    background-color: #fff;
}