@font-face {
    font-family: 'Ubuntu';
    font-weight: normal;
    font-style: normal;
    // src: url('../fonts/Ubuntu.woff') format('woff'), url('../../fonts/Ubuntu.ttf') format('truetype'), url('../fonts/Ubuntu.svg#Ubuntu-Bold') format('svg');
    src: url('../../../fonts/Ubuntu.woff') format('woff'), url('../../../fonts/Ubuntu.ttf') format('truetype'), url('../../../fonts/Ubuntu.svg#Ubuntu-Bold') format('svg');
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu-Regular';
    font-weight: normal;
    font-style: normal;
    src: url('../../../fonts/Ubuntu-Regular.eot');
    src: url('../../../fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'), url('../../../fonts/Ubuntu-Regular.woff') format('woff'), url('../../../fonts/Ubuntu-Regular.ttf') format('truetype'), url('../../../fonts/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu-Medium';
    font-weight: normal;
    font-style: normal;
    src: url('../../../fonts/Ubuntu-Medium.eot');
    src: url('../../../fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'), url('../../../fonts/Ubuntu-Medium.woff') format('woff'), url('../../../fonts/Ubuntu-Medium.ttf') format('truetype'), url('../../../fonts/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
    font-display: swap;
}

// .mat-table{
//     width:100%;
//     max-width : 100%;
//     margin-bottom : 1rem;
//     display: inline-block;
//     overflow-x: auto;
// }
// .table-responsive{
//     display: block;
//     width:100%;
//     overflow-x: auto;
//     -ms-overflow-style: -ms-autohiding-scrollbar;
// }
// .mat-table th,
// .mat-table td {
//     overflow : hidden;
//     min-width: 20px;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }
table.mat-table {
    width: 100%;
}

.matTable-container {
    height: 400px;
    max-width: 100%;
    overflow: auto;
}

.mat-paginator-container {
    min-height: 40px;
}

.mat-tab-label-container {
    flex-grow: 0 !important;
    margin: 0 auto !important;
}

//table header change
.mat-table th.mat-header-cell {
    padding-right: 1.5%;
    background-color: #5d5d62;
    // padding-right: 0%;
    // padding-left: 15px !important;
    white-space: nowrap;
    // font-size: 12px;
    font-size: 12px;
    font-family: 'Ubuntu-Medium', Verdana, sans-serif;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
}

.font {
    font-size: 12px;
    font-family: Ubuntu, Verdana, sans-serif;
  
}
.list {
    margin: 0 0 2em 0;
    list-style-type: none;
    padding: 0;
    width: 15em;
  }

td.mat-cell,
.mat-table th.mat-header-cell {
    border: 1px solid #d0d0d0;
    padding: 7px 10px 7px 7px !important;
    // word-break: break-all;
}

// td.mat-cell {
//     // white-space: nowrap;
// }

.mat-cell,
.mat-footer-cell {
    // font-size: 12px;
    font-family: Ubuntu, Verdana, sans-serif;
    // font-size: 14px;
    font-size: 12px;
    letter-spacing: 0px;
    color: #5A5A5F;
    opacity: 1;
}

// .mat-table tr td,
// th {
//     padding-right: 2%;
//     padding-left: 15px;
// }
th.mat-sort-header-sorted {
    color: #5A5A5F;
}

.mat-table tr.mat-row,
tr.mat-footer-row {
    height: 40px;
}

.mat-table tr.mat-header-row {
    height: 40px;
}

.mat-checkbox-layout .mat-checkbox-label {
    font-size: 12px;
}

.mat-sort-header-arrow {
    color: #ffff;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    .table-responsive {
        display: inline-flex;
        width: 100%;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .mat-table th,
    .mat-table td {
        overflow: hidden;
        min-width: 20px;
        // text-overflow: ellipsis;
        white-space: nowrap;
    }
    .mat-footer-row,
    .mat-header-row,
    .mat-row {
        display: inline-flex;
        min-width: 100%
    }
    .matTable-container {
        height: 450px;
        max-width: 100%;
        overflow: auto;
        border-radius: 5px;
    }
}

@media (min-width: 1200px) {
    .container {
        // max-width: 1225px !important;
        // max-width: 1325px !important; //change on 10 jan
        max-width: 1520px !important; //change on 19jan
        padding-left: 0px !important; //change on 19jan
    }
    .matTable-container {
        // height: 685px;
        // height: 700px;
        // height: 715px;
        height: 650px;
        max-width: 100%;
        overflow: auto;
        border-radius: 5px;
    }
    .col-9 {
        height: 650px !important;
    }
    .inSubMainHeaderClass {
        height: 603px !important;
    }
    .bottom-row-panel>.mat-expansion-panel-content {
        // height: 395px;
        // height: 375px;
        height: 376px;
    }
    .top-row-panel>.mat-expansion-panel-content {
        // height: 260px;
        height: 285px;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1900px !important;
        padding-left: 0px !important; //19jan
    }
}

@media (min-width: 1900px) and (min-height:768px) {
    .matTable-container {
        height: 650px !important;
        max-width: 100%;
        overflow: auto;
        border-radius: 5px;
    }
    .col-9 {
        height: 650px !important;
    }
    .inSubMainHeaderClass {
        height: 603px !important;
    }
}

//5sept
@media (min-width: 1920px) and (min-height:1080px) {
    .matTable-container {
        height: 650px !important;
        max-width: 100%;
        overflow: auto;
        border-radius: 5px;
    }
    .col-9 {
        height: 650px !important;
    }
    .inSubMainHeaderClass {
        height: 603px !important;
    }
    .bottom-row-panel>.mat-expansion-panel-content {
        // height: 395px;
        // height: 375px;
        height: 376px;
    }
    .top-row-panel>.mat-expansion-panel-content {
        // height: 260px;
        height: 285px;
    }
}

.app-inner {
    margin: 5px 3%;
}

//detail card css
.label {
    // font-size: 20px !important;
    // font-weight: 500 !important;
    // color: #ef2637;
    // font-size: 18px;
    font-size: 16px;
    color: #ef2637;
    font-family: Ubuntu-Medium, Verdana, sans-serif;
}

.labelSecondary {
    // font-size: 24px !important;
    // font-weight: 500 !important;
    // color: #5A5A5F;
    font-family: Ubuntu-Medium, Verdana, sans-serif;
    text-align: left;
    // font-size: 18px;
    font-size: 14px;
    letter-spacing: 0;
    color: #5a5a5f;
    opacity: 1;
}

.detail-card {
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 8%);
    border: 1px solid #d4d2d2;
    background-color: #fff;
    font-size: 12px;
    max-height: 230px;
    min-height: 230px;
    margin-bottom: 17px;
    font-family: Ubuntu, Verdana, sans-serif;
    overflow-y: auto;
}

.label1 {
    // font-size: 14px;  
    font-size: 14px;
    font-family: Ubuntu, Verdana, sans-serif;
}

.table-label {
    font-family: Ubuntu-Medium, Verdana, sans-serif;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0;
    color: #5a5a5f;
    opacity: 1;
}

.label-red-line {
    margin-top: 10px!important;
    margin-bottom: 15px!important;
    width: 35px;
    height: 4px;
    background-color: #ef2637;
}

img,
svg {
    cursor: pointer !important;
}

.tabcss {
    margin-right: 2%;
    margin-left: 1%;
}

//===========
.gereralConfig {
    text-align: left;
    font-size: 16px;
    padding-bottom: 15px;
    letter-spacing: 0;
    color: #5a5a5f;
    opacity: 1;
}

$gutter: 1rem !default;
.filter {
    width: 100%;
    margin-top: 5px;
}

// .mat-form-field {
//     width: 100%;
//     margin-top: 5px;
//     margin-bottom: 13px;
// }
.mat-form-field,
.mat-select,
.mat-option,
.mat-expansion-panel,
.mat-expansion-panel-header-title,
.mat-expansion-panel-body {
    // font-size: 14px;
    font-size: 12px;
    /* line-height: 40px; */
    font-family: Ubuntu, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #5A5A5F;
    opacity: 1;
}

.mat-expansion-panel-header.mat-expanded {
    height: 35px !important;
}

.mat-expansion-panel-spacing {
    margin-top: 16px !important;
}

.mat-expansion-panel {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    width: 99%;
}

.top-row-panel>.mat-expansion-panel-content {
    // height: 260px;
    // height: 225px;
    height: 230px;
}

// .bottom-row-panel>.mat-expansion-panel-content {
//     height: 400px;
//     // height: 350px;
// }
.tableHeaderLable {
    // font-weight: bolder;
    // font-size: 14px;
    font-size: 12px;
    font-family: 'Ubuntu-Medium', Verdana, sans-serif;
    letter-spacing: 0px;
    // color: #5A5A5F;
    color: #212529;
    opacity: 1;
    white-space: pre-wrap;
}

// table
// th {
//     font-size: 14px !important;
//     color: #5A5A5F !important;
// }
td {
    border-top: 1px solid #dee2e6;
}


/* for dialog pop-up */

// tr.mat-row,
// tr.mat-footer-row,
// tr {
//     height: 48px;
//     cursor: inherit !important;
// }
// .table> :not(:first-child) {
//     border-top: 1px solid currentColor !important;
// }
.table> :not(:first-child) {
    border-top: 1px solid currentColor !important;
}


/* for dialog pop-up */

.btn-dialog-close {
    width: 45px;
    min-width: 0px !important;
    height: 40px;
    padding: 0px !important;
}


/* mat-confirm-dialog (customised dialog) */

.confirm-dialog-container .mat-dialog-container {
    border-radius: .25em .25em .4em .4em;
    padding: 0px;
    margin-top: 88px !important;
}

.confirm-dialog-container .content-container {
    margin: 5px 5px 15px 5px;
    color: #8f9cb5;
    display: flex;
}

.confirm-dialog-container #close-icon {
    margin-left: auto;
    order: 2;
    font-weight: bolder;
}

.confirm-dialog-container #close-icon:hover {
    cursor: pointer;
}

.confirm-dialog-container #no-button {
    height: 50px;
    width: 50%;
    background-color: #fc7169;
    color: white;
    border-radius: 0px;
}

.confirm-dialog-container #yes-button {
    height: 50px;
    width: 50%;
    background-color: #b6bece;
    color: white;
    border-radius: 0px;
}

.confirm-dialog-container span.content-span {
    padding: 35px 16px;
    text-align: center;
    font-size: 20px;
}

.channelSection .mat-select-arrow {
    color: white !important;
}

.mat-card-actions {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.material-icons {
    font-size: 19px;
}

.csrPointer {
    cursor: pointer;
}

.align-self-center {
    align-self: center!important;
}

.content-card {
    padding: 55px 30px;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 4px 8px #00000029;
    border-radius: 8px;
    opacity: 1;
    padding-bottom: 0px;
    // top: 75px
    top: 65px //change on 19jan
}

.searchInfo {
    padding-top: 20px;
    cursor: pointer;
    padding-left: 1%;
}


/* Scrollbar Styling */

 ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

 ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.8em 0 1em 0 !important;
    border-top-width: inherit !important;
    font-size: initial !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0.5em !important;
}

.addNewIcon {
    color: #d33939;
    border: 2px solid;
    padding: 3px;
    height: 30px !important;
    width: 32px !important;
    text-align: center;
    font-size: 21px;
    cursor: pointer !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0.5em !important;
}

.confirm-dialog-container .mat-dialog-container {
    border-radius: .25em .25em .4em .4em;
    padding: 0px;
    margin-top: 88px !important;
}

.confirm-dialog-container .content-container {
    margin: 5px 5px 15px 5px;
    color: #8f9cb5;
    display: flex;
}

.confirm-dialog-container #close-icon {
    margin-left: auto;
    order: 2;
    font-weight: bolder;
}

.confirm-dialog-container #close-icon:hover {
    cursor: pointer;
}

.confirm-dialog-container #no-button {
    height: 50px;
    width: 50%;
    background-color: #fc7169;
    color: white;
    border-radius: 0px;
}

.confirm-dialog-container #yes-button {
    height: 50px;
    width: 50%;
    background-color: #b6bece;
    color: white;
    border-radius: 0px;
}

.confirm-dialog-container span.content-span {
    padding: 35px 16px;
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    overflow-wrap: break-word;
}

.green-snackbar {
    border: 2px solid #1baa1b;
    background: white;
    color: black;
    position: absolute;
    top: 80%;
    right: 5%;
    transform: translate(-50%, -50%);
    overflow-wrap: break-word;
    // bottom: 10px;
}

.red-snackbar {
    border: 2px solid #ef2637;
    background: white;
    color: black;
    position: absolute;
    top: 42%;
    right: 35%;
    transform: translate(-50%, -50%);
    overflow-wrap: break-word;
}

.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
}

.flex-child:first-child {
    margin-right: 20px;
}

.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
}

.confirm-dialog-container span.content-span {
    font-size: 17px !important;
    color: #212529 !important;
}

.column-padding {
    padding-right: 10px;
}

.table-row-select {
    cursor: pointer;
}

.table-row-select:focus {
    background: #b3d4eb !important;
    outline: none;
}

.table-row-select:focus mat-cell {
    color: white;
}

strong {
    padding-right: 10px !important;
}

.downloadExcelFile {
    display: inline-block;
    float: right;
    font-size: 14px;
    margin-top: 45px;
    margin-right: 10px;
    font-family: Ubuntu, Verdana, sans-serif;
}

// div.dual-list div.listbox div.button-bar
// div.dual-list div.listbox button[name=addBtn],
// div.dual-list div.listbox button[name=remove],
div.dual-list div.listbox div.button-bar {
    display: none
}

div.dual-list div.listbox div.record-picker label {
    font-size: 12px;
}

.edit_note_icon {
    font-size: 29px;
    vertical-align: middle;
    cursor: pointer;
    margin-bottom: 5px;
}

.mat-button-toggle {
    border-left: solid 0px #f14755;
}

.loaderSpinner {
    z-index: 999;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}
